.message-card {
  border-radius: 15px;
  margin-bottom: 0;
  width: 80%;
  gap: 8px;
}

.message-card:hover {
  background-color: #52449f21;
}

.message-sender {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0;
}

.search-input {
  height: 40px;
  border-radius: 40px;
}

.new-message-button {
  background-color: white;
  color: #52449f;
  border-radius: 25px;
  border: none;
}

.button-wrapper {
  display: flex;
  justify-content: end;
  margin-bottom: 14px;
}

.message-detail-date {
  background-color: #f4f4f4;
  color: #919498;
  font-weight: bold;
}

.message-detail-content {
  text-align: justify;
}

.message-drawer-content .ant-drawer-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.message-item-content {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.message-reciever {
  text-transform: capitalize;
  font-size: 12px;
}

.my-sent-message {
  // width: 80% !important;
}